import React from "react";
import { useTranslation  } from 'react-i18next';

function Reject(props){
  const { t } = useTranslation();
  return (
    <div data-testid = 'reject'>
      <h3 className="buttonLabel" dangerouslySetInnerHTML={{__html:t("button.reject")}}/>
    </div>
  )
}

export default Reject;
