import React, {useState, useEffect} from 'react';
import {DataGrid} from '@cib-jpm-ui-toolkit/data-grid';
import {Button} from '@cib-jpm-ui-toolkit/button';
import {LicenseManager} from 'ag-grid-enterprise';
import axios from 'axios';
import dataGridExampleColumns from './dataGridColumnDef.js';
import './Admin.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {Dropdown} from "@cib-jpm-ui-toolkit/dropdown";
import {Icon} from '@cib-jpm-ui-toolkit/icon';
//import { Banner } from '@cib-jpm-ui-toolkit/banner';// need to add  with updated component
import { getMonthsList } from "./DatesList";

LicenseManager.setLicenseKey('your license key');
const getDate = (val) => {
  let date;
  date = (val.split("'")[0].slice(0, 3) + '-' + val.split("'")[1]).replace(" ", "")
  return date;
}
const AdminDataGrid = props => {
  const [isGridReady, setIsGridReady] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [selectedDate, setSelectedDate] = useState(getDate(getMonthsList(3)[0]));
  const [gridData, setGridData] = useState([]);
  const [gridHeight, setGridHeight] = useState([]);
  // dataGridExampleData
  useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  const onGridReady = ({api}) => {
    setGridApi(api);
    setIsGridReady(true);
  };

  const handleButtonClick = () => {
    if (gridApi) {
      gridApi.exportDataAsExcel();
    }
  };


  const dateSelectHandeler = (date) => {
    setSelectedDate(getDate(date));
  }

  const loadData = () => {
    (async () => {
      let urlstr = window.location.href;
      let restUrl = (urlstr.replace('react', 'rest')).replace('admin', '');
      let url = `${restUrl}admin/userReport/${selectedDate}`;
      let apiRes = null;
      try {
        apiRes = await axios.get(url);
      } catch (err) {
        apiRes = err.response;
      } finally {
        if(apiRes?.statusText === 'Not Found' || apiRes?.data?.length === 0){
          setGridData([]);
          /* Uncomment below line for dummy data */
       // setGridData(dataGridExampleData);
        }else{
          setGridData(apiRes.data);
        }

      }
    })();
  }
  const getGridHeight = () => {
    const viewportHeight = "100%";
    return viewportHeight;
  }
  useEffect(() => {
    setSelectedDate(getDate(getMonthsList(3)[0]));
  }, [])
  useEffect(() => {
    loadData()
  }, [selectedDate]);

  return (
    <div>
      <div className='tools-container' id='tools'>
        <div className='date-list'>
          <Dropdown
            initialSelectedItem={getMonthsList(3)[0]}
            source={getMonthsList(3)}
            buttonWidth={150}
            onSelect={val => {
              dateSelectHandeler(val)
            }}
          />
        </div>
        <Button
          disabled={!isGridReady}
          onClick={handleButtonClick}
          className='export-button'
          style={{backgroundColor: 'transparent', width: '35px'}}
        >
          <Icon
            className='home-icon'
            accessibleText="export to excel"
            name="export"
            size={18}
            color='#000000'
          />
        </Button>
      </div>
    <div className='grid-container' data-testid='admindatagrid' style={{height: getGridHeight(), paddingBottom: "30px"}} id="data-grid">
      <div>
        {gridData?.length === 0 ?
          <div>
            {/*<Banner state="error">
              Data not found, please try again later
            </Banner>*/}
          </div>:<
            DataGrid
            columnDefs={dataGridExampleColumns}
            onGridReady={onGridReady}
            rowData={gridData}
            rowStripes
            {...props}
          />}

      </div>
    </div>
    </div>
  );
};

export default AdminDataGrid;
