import React from 'react';
import { Trans,useTranslation  } from 'react-i18next';
import LanguageContext from '../Context/LanguageContext';

function Panel_2(props){
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className="accordianPannelColor">
       <p className="info-text" id="complaints_3" dangerouslySetInnerHTML={{__html:t("page.complaintprocess.text")}}></p>
    </div>
    </>
  )
}

export default Panel_2;
