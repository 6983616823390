import React from "react";
import { Trans,useTranslation  } from 'react-i18next';

function Heading_2(props){
  const { t, i18n } = useTranslation();
  return (
    <div className='accordian-heading-design'>
      <h3 className='accordianHeading' dangerouslySetInnerHTML={{__html:t("page.complaintprocess.header")}}/>
      <hr/>
    </div>
  )
}

export default Heading_2;
