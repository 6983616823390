import React from 'react';
import { Toolbar } from '@cib-jpm-ui-toolkit/toolbar';
import {Link} from "react-router-dom";
import { Switch } from '@cib-jpm-ui-toolkit/switch';
import { Icon } from '@cib-jpm-ui-toolkit/icon';
import './App.css';

class ActionsToolbar extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      theme: 'dark',
      checked: false
    };
    this.toggleTheme = this.toggleTheme.bind(this);
  }
  toggleTheme =() => {
    const theme = this.state.theme === 'light' ? 'dark' : 'light';
    this.setState({ theme });
    document.documentElement.setAttribute("data-theme", theme);
  }
  handleChange =() => {
    const check = this.state.checked === false ? true : false;
    this.setState({ checked:check });
    this.toggleTheme();
  }
  showHome = () => {
    let urlstr = window.location.href;
    let adminIndex = (urlstr.replace('react', 'rest')).indexOf('admin');
    if(adminIndex < 0 ){
      return (
        <Link to={{pathname: '/'}} style={{textDecoration: "underline"}}>
          <Icon
            className='home-icon'
            accessibleText="Notification"
            data-jpmui-test="icon-access-text-example"
            name="home"
            size={18}
            color='#ffffff'
          />
        </Link>
      )
    }
  }
  render()
  {
    return (
      <Toolbar {...this.props}>
        {this.showHome()}
        <Switch checked={this.state.checked} label="" onChange={this.handleChange} className='theme-toggle-button' color="secondary"/>
      </Toolbar>
    )
  }
};

export default ActionsToolbar;
