const dataGridExampleColumns = [
  {
    headerName: 'IP Address',
    field: 'ipaddress',
    type: 'numericColumn'
  },
  {
    headerName: 'Date',
    field: 'date',
    type: 'numericColumn'
  },
  {
    headerName: 'Action',
    field: 'action',
    cellClass: ['text-cell']
  },
  {
    headerName: 'Isin',
    field: 'isin',
    cellClass: ['text-cell']
  },
  {
    headerName: 'Language',
    field: 'language',
    cellClass: ['text-cell']
  },
  {
    headerName: 'Status',
    field: 'status',
    cellClass: ['text-cell']
  },
];

export default dataGridExampleColumns;
