import React from 'react';
import { useTranslation  } from 'react-i18next';

function TandCText(props){
  const { t, i18n } = useTranslation();
  const langArr = ['fr', 'de', 'el', 'en','es','it','pt'];
  return (
    <span dangerouslySetInnerHTML={{__html:t("disclaimer.body")}}/>
  )
}

export default TandCText;
