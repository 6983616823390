import React, {Suspense} from 'react';
import { Accordion, ExpansionPanel } from '@cib-jpm-ui-toolkit/accordion';
import { Spinner } from '@cib-jpm-ui-toolkit/spinner';
import './accordian.css';
import Heading_1 from "./Heading_1";
import Heading_2 from "./Heading_2";
import Heading_3 from "./Heading_3";
import Panel_3 from "./Panel_3";
import Panel_2 from "./Panel_2";
import Panel_1 from "./Panel_1";

const panelsData = {
  panel1: {
    summary: (<Suspense fallback={<div><Spinner className='accordian-spinner-style'/></div>}><Heading_1/></Suspense>),
    content: (<Panel_1/>),
  },
  panel2: {
    summary: (<Suspense fallback={<div><Spinner className='accordian-spinner-style'/></div>}><Heading_2/></Suspense>),
    content: (<Panel_2/>)
  },
  panel3: {
    summary: (<Suspense fallback={<div><Spinner className='accordian-spinner-style'/></div>}><Heading_3/></Suspense>),
    content: (<Panel_3/>)
  }
};
const Accordian = props => (

    <div className='accordianContent '>
      <Accordion

        panels={['panel1', 'panel2', 'panel3']}
        renderPanel={({ value, getPanelProps }) => {
          const panel = panelsData[value];
          return (

            <ExpansionPanel
              content={panel.content}
              summary={panel.summary}
              {...getPanelProps()}
            />
          );
        }}
        {...props}
      />
    </div>
);

export default Accordian;
