import React from 'react';
import AdminDataGrid from "./AdminDataGrid";

function Admin(props){

  return (
    <>
      <AdminDataGrid/>
    </>
  )
}

export default Admin;
