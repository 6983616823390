import React from 'react';
import "./Footer.css";
import TermsAndConditions from '../termsAndConditions/TermsAndConditions'
import PrivacyAndSecurity from '../privacyAndSecurity/PrivacyAndSecurity'
import CookiePolicy from '../cookiePolicy/CookiePolicy'

function Header(){
    return (
        <>
            <nav className="footer-nav">
                <div className="footer-links">
                    <TermsAndConditions />
                    {" | "}
                    <a href="https://www.jpmorgan.com/country/GB/en/privacy" title="Cookie policy page link" target="ext">
                     <PrivacyAndSecurity />
                    </a>
                    {" | "}
                  <a href="https://www.jpmorgan.com/country/GB/en/cookies" title="Cookie policy page link" target="ext">
                    <CookiePolicy />
                  </a>
                </div>
            </nav>
        </>
    )
}

export default Header;
