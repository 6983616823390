export const getSpecificMonth = (loopback = 0) => {
 const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
 const date = new Date();
 let year = String(date.getFullYear());
 const dateNumber = date.getMonth() - loopback;
  year = dateNumber >= 0 ? year : date.getFullYear() - 1;
 const index = dateNumber >= 0 ? dateNumber : 12 + dateNumber; // 12 months in a year
 return months[index]+"' "+year;
};

export const getMonthsList = (count) => {
  let monthsList = [];
  for(let i = 0; i<count; i++){
    monthsList.push(getSpecificMonth(i));
  }
  return monthsList;
}
