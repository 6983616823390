import React from "react";
import { useTranslation  } from 'react-i18next';

function Message(props){
  const { t } = useTranslation();
  return (
    <div className='centerAlign' data-testid='message'>
      <h3 className='messageText-heading' dangerouslySetInnerHTML={{__html:t(props.message)}}/>
    </div>
  )
}

export default Message;
