import React from 'react';
import { Icon } from '@cib-jpm-ui-toolkit/icon';

const ErrorIcon = (props) => {
  return(
    <Icon
      className='message-icon'
      accessibleText="Error"
      data-jpmui-test="icon-access-text-example"
      name="error"
      size={20}
    />
  );
}

export default ErrorIcon;
