import React, { Component } from 'react';
import LanguageContext from './LanguageContext';
import i18n from '../../i18n'
class Languageprovider extends Component{
  constructor(props){
    super(props)
    this.state ={
      language:'en'
    }
    this.setLanguage = this.setLanguage.bind(this)
  }

  setLanguage = language =>{
    this.setState(prevState => ({language}),()=>i18n.changeLanguage(this.state.language))
  }
  componentDidMount(){
    const {i18n } = this.props
  }

  render(){

      const {language} = this.state
      const {setLanguage} = this

    return(
      <LanguageContext.Provider value={{language,setLanguage}}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}
export default Languageprovider;
