import React from 'react';
import { useTranslation  } from 'react-i18next';


function SearchHeader(props){
  const { t } = useTranslation();
  return (
    <span className="heading-text" dangerouslySetInnerHTML={{__html:t("page.search.header")}}/>
  )
}

export default SearchHeader;
