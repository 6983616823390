import React, {Suspense} from "react";
import { Trans,useTranslation  } from 'react-i18next';
import { Icon } from '@cib-jpm-ui-toolkit/icon';
import './PDFDownload.css'
function DocumentFound(props){
  const { t, i18n } = useTranslation();
  return (
    <div className='centerAlign'>
      <Icon
        className='tick-icon'
        accessibleText="Pdf"
        data-jpmui-test="icon-access-text-example"
        name="tick"
        size={20}
      /><h3 className='found-message' dangerouslySetInnerHTML={{__html:t(props.foundMessage)}}/><h3 className='message'>{props.isin}</h3>
    </div>
  )
}

export default DocumentFound;
