import React, { useState } from 'react';
import { Button } from '@cib-jpm-ui-toolkit/button';
import TermsContent from'./TermsContent';
import TnC from './TnC'
import TandCHeader from './TandCHeader'
import { useTranslation  } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@cib-jpm-ui-toolkit/dialog';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const [open, setOpen] = useState(false);

  const handleRequestOpen = () => setOpen(true);

  const handleRequestClose = () => setOpen(false);

  return (
    <>
      <Button data-jpmui-test="dialog-button" onClick={handleRequestOpen} className="buttonStyle">
        <TnC />
      </Button>
      <Dialog
        className="popupStyle"
        maxWidth='lg'
        disableBackdropClick="false"
        onClose={handleRequestClose}
        open={open}
        render={({
                   Icon,
                   getIconProps,
                   getTitleProps,
                   getContentProps,
                   getActionsProps
                 }) => (
          <>
            <Icon {...getIconProps()} />
            <DialogTitle {...getTitleProps()}>
              <h2 className="termsHeading"><TandCHeader /></h2>
            </DialogTitle>
            <DialogContent {...getContentProps()} style={{zIndex: 10}}>
              <TermsContent />
            </DialogContent>
          </>
        )}
      />
    </>
  );
};

export default TermsAndConditions;
