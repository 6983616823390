import React, {Suspense} from "react";
import { Trans,useTranslation  } from 'react-i18next';
import { Icon } from '@cib-jpm-ui-toolkit/icon';
import './PDFDownload.css'
function Message(props){
  const { t, i18n } = useTranslation();
  return (
    <div className='centerAlign'>
     <h3 className='message' dangerouslySetInnerHTML={{__html:t(props.message)}}/>
    </div>
  )
}

export default Message;
