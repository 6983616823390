import React, {useEffect, useState} from 'react';
import './TermsAndConditions.css';
import TandCText from './TandCText'
function TermsContent(){
 const [termsHeight, setTermsheight] = useState();
  useEffect(() => {
    setTermsheight(document.getElementsByClassName("popupStyle")[0].offsetHeight - 120 + 'px')
    console.log()
  })

  return (
    <>
      <div className="terms-body-cotent" tabIndex="0" data-testid='terms-content'>
        <div className="terms-of-user-content">
            <div className="terms-of-user-container" style={{height: termsHeight}}>
            <div className="content" data-view="terms"></div>
            <div className="content" data-view="present-item" id="terms-content-style"><TandCText />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsContent;
