import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import { ColumnLayout } from '@cib-jpm-ui-toolkit/column-layout';
import Home from '../Home/Home';
import Isinsearch from '../IsinSearch/Isinsearch';
import Admin from '../Admin/Admin';
import LanguageProvider from '../Context/Languageprovider'
import './main.css'
import './App.css';
import AppNav from './AppNav';


const styles = {
  root: {
    '& .content': {
      margin: -8
    }
  },
  panel: {
    padding: 16,
    textAlign: 'center',
    marginTop:'50px'
  },
  spinner: {
    display: 'inline-block'
  }
};
class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      theme: 'light',
    };
    this.toggleTheme = this.toggleTheme.bind(this);
  }
  toggleTheme() {
    const theme = this.state.theme === 'dark' ? 'light' : 'dark';
    this.setState({ theme });
    document.documentElement.setAttribute("data-theme", theme);
  }
  render(){

    return(
      <LanguageProvider>
        <ColumnLayout style={styles.root} container data-testid='app'>
          <AppNav />
          <ColumnLayout item xs={12} className='contentPosition'>
            <div style={styles.panel} id="panel-rout">
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/priips" component={Home}/>
                <Route exact path="/priips/" component={Home}/>
                <Route path="/doc_search" component={Isinsearch}/>
                <Route path="/admin" component={Admin}/>
              </Switch>
              </div>
            </ColumnLayout>
          </ColumnLayout>
        </LanguageProvider>
    )
  }
}

export default withRouter(App);
