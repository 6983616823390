import React from 'react';
import { useTranslation  } from 'react-i18next';

function SearchLabel(props){
  const { t, i18n } = useTranslation();
  const langArr = ['fr', 'de', 'el', 'en','es','it','pt'];
  return (
    <span className="search-label-text" dangerouslySetInnerHTML={{__html:t("page.search.searchbox")}}/>
  )
}

export default SearchLabel;
