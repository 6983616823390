import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createToolkitTheme } from '@cib-jpm-ui-toolkit/theme';
import '@cib-jpm-ui-toolkit/style/css/jpmuitk.css';

import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

const theme = createToolkitTheme('light');

function Root() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
          <App className='appPosition' />
      </ThemeProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
