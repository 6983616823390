import React from "react";
import { useTranslation  } from 'react-i18next';

function DisclaimerContent(props){
  const { t } = useTranslation();
  return (
    <div data-testid='disclaimer'>
      <h3 dangerouslySetInnerHTML={{__html:t("disclaimer.residency")}}/>
    </div>
  )
}

export default DisclaimerContent;
