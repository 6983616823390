import React, {useState, useEffect, Component, Suspense} from 'react';
import PropTypes from 'prop-types';
import './SearchPage.css';
import Accordian from '../accordian/Accordian';
import SearchHeader from './SearchHeader';
import SearchLabel from './SearchLabel';
import { ColumnLayout } from '@cib-jpm-ui-toolkit/column-layout';
import { Panel } from '@cib-jpm-ui-toolkit/panel';
import { FormField } from '@cib-jpm-ui-toolkit/form-field';
import { Search } from '@cib-jpm-ui-toolkit/search';
import '@cib-jpm-ui-toolkit/style/css/jpmuitk.css';
import Footer from '../common/Footer';
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import './SearchPage.css';
import {
  ThemeProvider,
  createToolkitTheme
} from '@cib-jpm-ui-toolkit/theme';
import AccordianHeading from "./AccordianHeading";
import PDFDownload from "../PDFDownload/PDFDownload";
import axios from "axios";
import { Card } from '@cib-jpm-ui-toolkit/card';
import { Spinner } from '@cib-jpm-ui-toolkit/spinner';
import i18n from 'i18next';

const LIGHT_THEME = createToolkitTheme('light');
const handleChange = ({ target: { value } }) => {
  /*console.log(`New value: ${value}`);*/
};

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    margin: '0 auto'
  },
  panel: {
    textAlign: 'center'
  }
};


const getLanguage = () => {
  return i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en';
};

class Isinsearch extends Component{

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      showMessage: false,
      pdfPopup: false,
      message: '',
      languagesJson:'',
      languages:[],
      pdfURl:'',
      ISINSpinner:'none'
    };
  }

  changeBackground(){
    document.body.style.backgroundColor = "#242526";
  }
  showErrorMessage = () =>{
    this.refs.messageRef.handleRequestOpen();
  }

  showPdfDownload = () =>{
    this.refs.pdfRef.handleRequestOpen();
  }
  resetPdfPopup = () =>{
    this.setState({pdfPopup:false});
  }
  resetErrorfPopup = () =>{
    this.setState({showMessage:false});
  }
  handleSubmit = (event) => {
    if(document.getElementById('error-dialog') || document.getElementById('pdf-dialog')){
      return false;
    }
    this.setState({ISINSpinner:'block'});
    this.loadData();
  };

  loadData = () =>{
    (async() => {
      let urlstr = window.location.href;
      let restUrl = (urlstr.replace('react','rest')).replace('doc_search','');
      let url = `${restUrl}priips/document/${this.state.value}/languages`;
      let apiRes = null;

      try {
        apiRes = await axios.get(url, {
          params: {
            locale: getLanguage()
          }
        });
      } catch (err) {
        apiRes = err.response;
      } finally {
        this.setState({ISINSpinner:'none'});
        if(apiRes.data.error){
          this.setState({showMessage:true,pdfPopup:false,message:apiRes.data.error.errorCode},()=>{this.handlePopup()});
        }else{
          this.setState({languagesJson:apiRes.data,showMessage:false,pdfPopup:true},()=>{
            this.languagesArray();
            this.handlePopup();

          })
        }
      }
    })();
  }
  languagesArray = () =>{
    var LanguagesArr = [];
    var LanguagesKey = [];
    var languagesObj = this.state.languagesJson['languages'];
    for(var key in languagesObj ){
      LanguagesArr.push(languagesObj[key]);
      LanguagesKey.push(key);

    }
    this.setState({languages:LanguagesArr})
    this.setState({languagesKeys:LanguagesKey})
  }
  handlePopup = () =>{
    if (this.state.showMessage) {
      this.showErrorMessage()
    }else if(this.state.pdfPopup){
      this.showPdfDownload()
    }
  }
  handleChange = event => {
    this.setState({
      value: event.target.value
    });
    this.props.onChange(event);
  };
  showHideMessage = (show,callback) => {
    this.setState({showMessage:show},()=>{
      callback();
    });
  }
  showHidePdfPopup = (show,callback) => {
    this.setState({pdfPopup:show},()=>{
      callback();
    });
  }

  render(){
    this.changeBackground();
    const searchProps = { ...this.props, autoComplete: 'off' };
    return (

      <div id='isin-search' data-testid='isinsearch'>
        <div data-jpmui-test="column-layout-example" style={styles.root}>
          <ColumnLayout container spacing={24} className='search-component-holder'>
              <ColumnLayout item xs={12} id='searchLayout'>
                <Panel className='searchPanel'>
                  <div className="search-page-headings">
                    <Suspense fallback={<div>Loading...</div>}><SearchHeader/></Suspense>
                  </div>
                  <div className='searchFormContainer'>
                    <FormField className='form-field form-style' variant="transparent">
                      {({ getChildProps }) => <div><div className="search-form-label"><Suspense fallback={<div>Loading...</div>}><SearchLabel/></Suspense></div>
                        <div className="search-input-style"><Search
                        name='search-box'
                        className='isin-search-box'
                        {...getChildProps(searchProps)}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                      /></div></div>}
                    </FormField>
                  </div>
                  <div style={{display:this.state.ISINSpinner }}>
                    <Spinner className='spinner-style'/>
                  </div>
                  <hr style={{display:(this.state.ISINSpinner === 'none')? 'block': 'none'}} className='seperator devider'/>

                </Panel>

              </ColumnLayout>
              <ColumnLayout item xs={12} id='complaintsLayout'>
                <div className="search-page-headings">
                  <Suspense fallback={<div><Spinner className='accordian-spinner-style'/></div>}>
                    <AccordianHeading/>
                  </Suspense>
                </div>
                <Card className='complain-card'>
                  <div id='complaintsContainer'>
                    <Accordian className='accordianComponent' />
                  </div>

                </Card>
              </ColumnLayout>
          </ColumnLayout>
        </div>
        <Footer className='footerPosition' id='footer'/>
        <div>
          <ErrorMessage ref='messageRef' showHide={this.showHideMessage} message={this.state.message}/>
        </div>
        <div>
          <PDFDownload ref='pdfRef' showHide={this.showHidePdfPopup} message={this.state.message} pdfUrl={this.state.pdfURl} searchedIsin={this.state.value} languagesArr={this.state.languages} languagesKeyArr={this.state.languagesKeys}/>
        </div>
      </div>

    )

  }

}
Isinsearch.defaultProps = {
  onChange: handleChange
};

Isinsearch.propTypes = {
  onChange: PropTypes.func
};

export default Isinsearch;

