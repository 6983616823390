import React from 'react';
import { Icon } from '@cib-jpm-ui-toolkit/icon';

const PdfIcon = (props) => {
  return(
    <Icon
      accessibleText="Pdf"
      data-jpmui-test="icon-access-text-example"
      name="document-pdf"
      size={35}
    />
  );
}

export default PdfIcon;
