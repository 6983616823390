import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@cib-jpm-ui-toolkit/theme';
import { AppHeader } from '@cib-jpm-ui-toolkit/app-header';
import { Tabs, Tab } from '@cib-jpm-ui-toolkit/tabs';
import { ThemeProvider, createToolkitTheme  } from '@cib-jpm-ui-toolkit/theme';
import ActionsToolbar from './ActionsToolbar';
import '../common/header.css';

const DARK_THEME = createToolkitTheme('dark');
const styles = {
  root: {
    paddingRight: 24,
    '& .actions': {
      float: 'right',
      paddingTop: 8
    },
    '& .nav': {
      display: 'inline-block'
    }
  }
};

const AppNav = ({ classes }) => (
  <ThemeProvider theme={DARK_THEME}>
  <AppHeader
    id='main-header'
    data-testid='mainheader'
    className={classes.root}
    position="fixed"
    style={{backgroundColor: "#DE000JP2S2V3", fill:"#ffffff", zIndex:'2'}}
    color="primary"
    renderActions={({ getActionsProps }) => (
      <div {...getActionsProps()}>
        <ActionsToolbar />
      </div>
    )}
    renderNav={({ getNavProps }) => (
      <div {...getNavProps({ style: classes.nav })}>
        <Tabs
          height="100%"
          noBorder
          renderTab={({ getTabProps }) => <Tab {...getTabProps()}/>}
        />


      </div>
    )}
  />
    <div class="iewarning">
      <p class="ietext"><b>
        Please use Google Chrome or Mozilla Firefox browser to access this website. The website is not compatible with Internet Explorer browser.</b>
      </p>
    </div>
    </ThemeProvider>
);

AppNav.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string)
};

export default withStyles(styles)(AppNav);
