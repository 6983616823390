import React, { Component } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@cib-jpm-ui-toolkit/dialog';
import './ErrorMessage.css';
import Message from "./Message";
class ErrorMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      closeClicked:false
    };
  }

  openDilague = () => {
    this.setState({open:true});
  }
  closeDilague = () => {
    this.setState({open:false});
  }
  handleRequestOpen = (ev) => {
    this.props.showHide(true,this.openDilague);
  }

  handleRequestClose = (ev) => {
    if(!this.state.open){
      return false;
    }
    this.props.showHide(false,this.closeDilague);
  }
  render(){
    return (
      <>

        <Dialog
          id='error-dialog'
          data-testid='error-dialog'
          className='error-dialogue'
          onClose={this.handleRequestClose}
          open={this.state.open}
          render={({
                     Icon,
                     getIconProps,
                     getTitleProps,
                     getContentProps,
                     getActionsProps
                   }) => (
            <>
              <Icon {...getIconProps()} />
              <DialogTitle {...getTitleProps()}>
                Error!
              </DialogTitle>
              <DialogContent {...getContentProps()}>
                <div className='messageText'><Message message={this.props.message}/></div>
              </DialogContent>
            </>
          )}
          state="error"
        />
      </>
    );
  }

};

export default ErrorMessage;
