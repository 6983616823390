import React , { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from '@cib-jpm-ui-toolkit/dropdown';
import { ThemeProvider, createToolkitTheme } from '@cib-jpm-ui-toolkit/theme';
import { ColumnLayout } from '@cib-jpm-ui-toolkit/column-layout';
import { Button } from '@cib-jpm-ui-toolkit/button';
import LanguageContext from '../Context/LanguageContext';
import {
    Dialog,
    DialogContent,
    DialogActions
} from '@cib-jpm-ui-toolkit/dialog';
import './LanguageSelector.css';
import Languages from './Languages';
import DisclaimerContent from "./DisclaimerContent";
import Accept from "./Accept";
import Reject from "./Reject";

const LIGHT_THEME = createToolkitTheme('light');

class LanguagesSelector extends Component {

    constructor(props){
      super(props)
      this.state = {
        open: false,
        language: 'English',
        languageCode: 'en'
      };
    }

    static contextType = LanguageContext;

    handleRequestOpen = () => {
      this.setState({open: true});
      this.initiateLanguage();
    }

    handleRequestClose = () => this.setState({open: false});

    initiateLanguage = () =>{
      var languageCode = this.changeLanguage(this.state.language);
      this.context.setLanguage(languageCode);
    }
    changeLanguage = (lang) =>{
      switch(lang) {
        case 'English':
          return 'en'
          break;
        case 'Greek':
          return 'el'
          break;
        case 'German':
          return 'de'
          break;
        case 'French':
          return 'fr'
          break;
        case 'Italian':
          return 'it'
          break;
        case 'Spanish':
          return 'es'
          break;
        case 'Portuguese':
          return 'pt'
          break;
        default:
          return 'en'
      }
    }
    languageSelect = (lang) => {
      var languageCode = this.changeLanguage(lang);
      this.context.setLanguage(languageCode);
      this.setState({language: lang,languageCode:languageCode});

    }

    render() {

        return (
          <>
            <ThemeProvider theme={LIGHT_THEME}>
              <ColumnLayout item xs={12}  >
                    <Dropdown
                      className='lang-selector'
                      data-testid='language-dropdown'
                      initialSelectedItem={Languages[0]}
                      source={Languages}
                      buttonWidth="200px"
                      onSelect={(lang)=>this.languageSelect(lang)}
                      {...this.props}
                    />
                </ColumnLayout>
                <Dialog
                    id='agreement-popup'
                    className='agreement-dialog-box'
                    onClose={this.handleRequestClose}
                    open={this.state.open}
                    render={({
                                 Icon,
                                 getIconProps,
                                 getTitleProps,
                                 getContentProps,
                                 getActionsProps
                             }) => (
                        <>
                            <Icon {...getIconProps()} />
                            <DialogContent {...getContentProps()}>
                              <DisclaimerContent/>
                              <hr className='thin-separator'/>
                            </DialogContent>

                            <DialogActions {...getActionsProps()} id='actions'>
                              <Link to={{ pathname: '../doc_search', state: { code: this.props.accordiancontent} }} style={{color:"#ffffff", textDecoration:"none"}}><Button onClick={this.handleRequestClose} style={{cursor: 'pointer'}} tabIndex="0" className='accept-btn' id='AcceptBtn'>
                                <Accept/>
                                </Button>
                              </Link>
                                <Button onClick={this.handleRequestClose} variant="cta" className='deny-btn'><Reject/></Button>
                            </DialogActions>
                        </>
                    )}
                />

            </ThemeProvider>
            </>
            )}

};

export default LanguagesSelector;
