import React from 'react';
import '@testing-library/jest-dom';
import LanguagesSelector from '../languageSelector/LanguageSelector';
import {ColumnLayout} from '@cib-jpm-ui-toolkit/column-layout';
import {Button} from '@cib-jpm-ui-toolkit/button';
import {Card} from '@cib-jpm-ui-toolkit/card';
import './Home.css';

class Home extends React.Component {

  constructor() {
    super();
  }

  handleSelect = () => {
    this.refs.dropdownRef.handleRequestOpen()
  }

  render() {
    function changeBackground() {
      document.body.style.backgroundSize = 'cover';
    }

    changeBackground();
    return (
      <div id="component-home">
        <div className="row intro-content intro intro-heading" data-testid='intro-content'>
          <h1 className="intro-heading">J.P. Morgan Packaged Retail and Insurance based Investment Products ("PRIIPs")
          </h1>
        </div>
        <hr className='separetor'/>
        <div className='dropdownContainer'>

          <p className="animated fadeInUp intro-para">This website provides investors with access to Key Information
            Documents ("KIDs") and important information regarding J.P. Morgan in relation to PRIIPs
          </p>
          <ColumnLayout item xs={12}>
            <Card className='language-card'>
              <div className=" lang-select-button align-center">
                <Button
                  id='selectButton'
                  data-testid='select-button'
                  data-jpmui-test="button-default-example"
                  variant="cta"
                  className="language-button-style"  {...this.props}
                  onClick={
                    this.handleSelect
                  }
                >
                  SELECT
                </Button>
              </div>
            </Card>

          </ColumnLayout>
          <ColumnLayout item xs={12} className='dropdownColumn'>
            <label className='languages-label'>Please select your preferred language of website</label>
            <LanguagesSelector id='lang-selector' ref='dropdownRef'/>
          </ColumnLayout>
        </div>
      </div>
    )
  }
}

export default Home;
