import React from 'react';
import { useTranslation  } from 'react-i18next';

function TnC(props){
  const { t, i18n } = useTranslation();
  const langArr = ['fr', 'de', 'el', 'en','es','it','pt'];
  return (
    <u><span dangerouslySetInnerHTML={{__html:t("disclaimer.header")}}/></u>
  )
}

export default TnC;
