import React from "react";
import { useTranslation  } from 'react-i18next';

function Accept(props){
  const { t } = useTranslation();
  return (
    <div data-testid = 'accept'>
      <h3 className="buttonLabel" dangerouslySetInnerHTML={{__html:t("button.accept")}}/>
    </div>
  )
}

export default Accept;
