import React, {Suspense} from "react";
import { useTranslation  } from 'react-i18next';

function DownloadCsvButton(props){
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="buttonLabel" dangerouslySetInnerHTML={{__html:t("page.search.result.document.historic.btn")}}/>
    </div>
  )
}

export default DownloadCsvButton;
