import React from 'react';
import { useTranslation  } from 'react-i18next';

function AccordianHeading(props){
  const { t } = useTranslation();
  return (
    <span className="accordian-heading-text"  dangerouslySetInnerHTML={{__html:t("page.search.complaints.header")}}/>
  )
}

export default AccordianHeading;
