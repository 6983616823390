import React , { Component, Suspense } from 'react';
import { Dropdown } from '@cib-jpm-ui-toolkit/dropdown';
import { ThemeProvider, createToolkitTheme } from '@cib-jpm-ui-toolkit/theme';
import { ColumnLayout } from '@cib-jpm-ui-toolkit/column-layout';
import LanguageContext from '../Context/LanguageContext';
import { Icon } from '@cib-jpm-ui-toolkit/icon';
import { Spinner } from '@cib-jpm-ui-toolkit/spinner';
import axios from 'axios';
import {
    Dialog,
    DialogContent,
    DialogActions
} from '@cib-jpm-ui-toolkit/dialog';
import './PDFDownload.css';
import DownloadButton from "./DownloadButton";
import DownloadCsvButton from "./DownloadCsvButton";
import DownloadCsvHeading from "./DownloadCsvHeading";
import PdfIcon from "./PdfIcon";
import Message from "./Message";
import DocumentFound from "./DocumentFound";
import ErrorIcon from "./ErrorIcon";
import i18n from 'i18next';
import { Html5Entities } from 'html-entities';

const entities = new Html5Entities();
const LIGHT_THEME = createToolkitTheme('light');

class PDFDownload extends Component {


    constructor(props){
      super(props)

      this.state = {
        open: false,
        language: 'English',
        languageCode: 'en',
        url:'',
        historyUrl:'',
        enabling: 'download-btn-disabled',
        showHistoryButton: 'download-btn-disabled',
        pdfSpinner:'none',
        popupHeight:250,
        showError:false
      };

    }

    static contextType = LanguageContext;
  openDilague = () => {
    this.setState({open:true})
  }
  closeDilague = () => {
    this.setState({open:false,showError:false});
  }
  handleRequestOpen = (callback) => {

    var languageCode = this.changeLanguage(this.props.languagesArr[0])
    let urlstr = window.location.href;
    let restUrl = (urlstr.replace('react','rest')).replace('doc_search','');
    let url = `${restUrl}priips/document/${this.props.searchedIsin}/${languageCode}`;

    this.setState({language: this.props.languagesArr[0],languageCode:languageCode,url:url,enabling:'download-btn-disabled',popupHeight:250, showHistoryButton:'hide-button'},()=>{
      this.props.showHide(false,this.openDilague)
    })
  }
  handleRequestClose = () => {
    this.props.showHide(true,this.closeDilague)
  }
  handlePdfDownload = () =>{
    (async() => {
      let url = this.state.url;
      let apiRes = null;
      try {
        this.setState({pdfSpinner:'block',showError:false})
        apiRes = await axios.get(url);
      } catch (err) {
        apiRes = err.response;
      } finally {
        if(apiRes.data.error){
          this.setState({
            enabling:'download-btn-disabled',
            popupHeight:270,
            showError:true,
            pdfPopup:false,
            message:apiRes.data.error.errorCode,
            showHistoryButton:'hide-button'
          });
          this.setState({});
        }else{
          this.setState({enabling:'download-btn',popupHeight:400,showError:false, showHistoryButton:'download-csv-btn'})
        }
         this.setState({pdfSpinner:'none'})
      }
    })();
  }

    changeLanguage = (lang) =>{

      if(lang !=null && lang != undefined) {
        const langIndex = this.props.languagesArr.indexOf(lang);
        return this.props.languagesKeyArr[langIndex];
      }else{
        return 'en';
      }
    }

    languageSelect = (lang) => {

      var languageCode = this.changeLanguage(lang);
      let urlstr = window.location.href;
      let restUrl = (urlstr.replace('react','rest')).replace('doc_search','');
      let url = `${restUrl}priips/document/${this.props.searchedIsin}/${languageCode}/pop`;
      let historyUrl = `${restUrl}priips/document/historic/${this.props.searchedIsin}`;
      this.setState({language: lang,languageCode:languageCode,urlPop:url, historyUrl:historyUrl,enabling:'download-btn-disabled',popupHeight:250, showHistoryButton:'hide-button'})
      this.handlePdfDownload();
    }

    render() {
        return (
          <>
            <ThemeProvider theme={LIGHT_THEME}>
                <Dialog
                    id='pdf-dialog'
                    className='dialog-bag'
                    onClose={this.handleRequestClose}
                    open={this.state.open}
                    height={this.state.popupHeight}
                    render={({
                                 Icon,
                                 getIconProps,
                                 getTitleProps,
                                 getContentProps,
                                 getActionsProps
                             }) => (
                        <>
                            <Icon {...getIconProps()} />
                            <DialogContent {...getContentProps()} className="dialog-content" >
                              <ColumnLayout item xs={12}>
                                <span style={{display:(this.state.pdfSpinner === 'none' && this.state.showError === false)? 'block': 'none'}}><PdfIcon /></span>
                                <span style={{display:(this.state.showError === true)? 'block': 'none'}}>
                                  <ErrorIcon/><span className='error-text'>Error!</span>
                                </span>
                                <div style={{display:this.state.pdfSpinner }}>
                                  <Spinner className='spinner-style'/>
                                </div>
                                <div className={(this.state.enabling === 'download-btn-disabled') ? 'hidden' : 'showElement'}>
                                <div className="label-select"><DocumentFound foundMessage="page.search.result.text" isin={this.props.searchedIsin}/></div>
                                </div>
                                <div className={(this.state.showError) ? 'showElement' : 'hidden'}>
                                  <Message message={this.state.message}/>
                                  <hr className='thin-error-separator'/>
                                </div>
                                <div className='select-dropdown'>
                                  <Dropdown
                                    disabled={(this.state.pdfSpinner === 'none')? false: true}
                                    initialSelectedItem= {entities.decode(i18n.t("page.search.result.language.download"))}
                                    source={this.props.languagesArr}
                                    onSelect={(lang)=>this.languageSelect(lang)}
                                    {...this.props}
                                  />
                                </div>
                              </ColumnLayout>
                            </DialogContent>
                            <DialogActions {...getActionsProps()}>
                              <a href={this.state.urlPop} target='_blank' className={this.state.enabling}><DownloadButton /></a>
                            </DialogActions>
                            <div className={this.state.showHistoryButton === 'hide-button' ? 'hide-history' : 'show-history'}>
                              <div className='history-button-header'><DownloadCsvHeading/></div>
                              <DialogActions {...getActionsProps()}>
                                <a href={this.state.historyUrl} className={this.state.showHistoryButton}><DownloadCsvButton /></a>
                              </DialogActions>
                            </div>
                        </>
                    )}
                />

            </ThemeProvider>
            </>
            )}

};

export default PDFDownload;
